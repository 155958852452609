import List from './List';
import Show from './Show';
import { gql } from '@apollo/client';

export default {
  name: 'DataTest',
  inputShow: gql`
    query dataTests($input: DataTestQueryInput) {
      dataTests(input: $input) {
        id
        __typename
      }
    }
  `,
  list: List,
  show: Show,
  options: {
    group: 'Misc',
    label: 'Data Tests',
  },
};
