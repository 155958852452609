export const validateSlug = (value: string) => {
  if (value.length < 3) {
    return 'Must be at least 3 characters long';
  } else if (value.length > 256) {
    return 'Must be 256 characters or less';
  } else if (!/^[a-zA-Z0-9\_]+$/.test(value)) {
    return 'Only alphanumeric characters allowed';
  }
  return undefined;
};

export const validateNonZero = (value: number) => {
  if (value === 0) {
    return 'Must be non-zero';
  }
  return undefined;
};

export const validateEndsTime = (ends_time: string, allValues: any) => {
  const { starts_time } = allValues;
  if (!ends_time || !starts_time) {
    return undefined;
  }

  const starts = new Date(starts_time);
  const ends = new Date(ends_time);
  if (ends < starts) {
    return 'Ends time must be after starts time';
  }

  const NUM_MILLISECONDS_IN_DAY = 86400000;
  const diff = ends.getTime() - starts.getTime();
  if (diff > NUM_MILLISECONDS_IN_DAY) {
    return 'Shift must be less than 24 hours';
  }
};
