import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import LinkField from 'components/LinkField';
import DateTimeField from 'components/DateTimeField';

const DataTestShow = () => (
  <Show data-testid="funding-source-show" actions={null}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <LinkField source="entity_id" resource="Job" />
      <TextField source="entity_type" />
      <DateTimeField source="created_at" />
    </SimpleShowLayout>
  </Show>
);

DataTestShow.query = gql`
  query dataTest($id: Int!) {
    dataTest(id: $id) {
      id
      entity_id
      entity_type
      created_at
    }
  }
`;

export default DataTestShow;
