import React from 'react';
import { gql } from '@apollo/client';
import { TextField, Datagrid } from 'react-admin';
import List from 'components/List';
import DateTimeField from 'components/DateTimeField';
import LinkField from 'components/LinkField';
import IdField from 'components/IdField';

const DataTestList = () => (
  <List exporter={false} actions={null} sort={{ field: 'created_at', order: 'DESC' }}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField source="entity_id" resource="Job" />
      <TextField source="entity_type" />
      <DateTimeField source="created_at" />
    </Datagrid>
  </List>
);

DataTestList.query = gql`
  query dataTests($input: DataTestQueryInput) {
    _dataTestsMeta(input: $input) {
      count
    }
    dataTests(input: $input) {
      id
      entity_id
      entity_type
      created_at
    }
  }
`;

export default DataTestList;
