import React from 'react';
import { EditButton, useRecordContext, TopToolbar } from 'react-admin';
import config from 'config';
import { ENUMS } from 'components/Enum/enums';
import CopyLink from 'components/CopyLink';
import { CreateTestJobButton } from './CreateTestJobButton';

const JobShowActions = () => {
  const record = useRecordContext();

  return (
    <TopToolbar>
      {[ENUMS.Job.status.active].includes(record?.status) && (
        <CopyLink textToCopy={`${config.rails_url}/dashboard/jobs/${record.id}`} />
      )}
      <CreateTestJobButton />
      <EditButton data-testid="edit-button" />
    </TopToolbar>
  );
};

export default JobShowActions;
